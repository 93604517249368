import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import { reqTouristLogin, reqUserInfo } from '@/api/user'
import type { UserInfo } from '@/api/user/type.d.ts'
import { useState } from '@/composables/useState.ts'
import { SearchCacheItem } from '@/api/search/type'
import { IndexResponseItemData } from '@/api/home/type'
import { reqSingleList } from '@/api/search'
export const useUserStore = defineStore(
    'user',
    () => {
        // 是否登录，不是以token为参考，因为游客身份没有token, 但是，游客身份也是一个账号，他有id。
        const STORE_IS_LOGIN = computed(() => {
            return MY_USER_INFO.id
        })
        // 是否游客，如果是游客，只会有登录id,没有token
        const STORE_IS_TOURIST = computed(() => {
            return MY_USER_INFO.id && !token.value
        })
        // 是否注册用户，后台返回了token，并且有id
        const STORE_IS_REGISTERED = computed(() => {
            return !!MY_USER_INFO.id && !!token.value
        })
        // 定义状态
        const MY_USER_INFO = reactive<UserInfo>({} as UserInfo)
        //@todo 竟然没有发现他在哪里设置了 token, 原来是 在外部通过 xxx.token 来设置，这会让代码变得难以维护。
        const token = ref('')
        const SET_TOKEN = (value: string) => {
            token.value = value
        }
        const GET_IM_TOKEN = () => {
            return IM_TOKEN.value
        }
        const IM_TOKEN = ref('')
        const SET_IM_TOKEN = (value: string) => {
            IM_TOKEN.value = value
        }
        const CLEAR_TOKEN = () => {
            token.value = ''
            IM_TOKEN.value = ''
            MY_USER_INFO.id = 0
        }
        // 首页标签搜索
        const [tag, setTag] = useState('')
        const searchHistoryArr = ref<SearchCacheItem[]>([])

        // 根据searchKey来重新渲染页面
        const searchKey = ref(1)

        // work页面中，视频索引
        const WORK_ACTIVE_INDEX = ref(0)
        /**
         * 设置索引视频索引, 在多个场景下都会有用到
         * @todo 需要优化。如果是从别的地方进来，完全没有问题。但是如果遇到了用户强制刷新。WORK_ACTIVE_INDEX 会变成0，实际上，可能需要从query中去取。
         * @todo 强制刷新时，需要从query中取的情况，可以在main.js中设置下。设置成他的查询参数，如果有才设置。目前先不处理。
         * @param index
         * @constructor
         */
        const SET_WORK_ACTIVE_INDEX = (index: number) => {
            WORK_ACTIVE_INDEX.value = index
        }
        /**
         * 获取索引视频索引
         * @todo 备用，先不做处理
         * @constructor
         */
        const GET_WORK_ACTIVE_INDEX = () => WORK_ACTIVE_INDEX.value
        // 视频作品
        const userWorkList = ref<IndexResponseItemData[]>([])
        const setUserWorkList = (data: IndexResponseItemData[]) => {
            userWorkList.value = data
        }
        // 删除作品中的某一项，常用于视频403时执行。
        const REMOVE_USER_WORK_LIST = (index: number) => {
            if (userWorkList.value.length - 1 > index) {
                userWorkList.value.splice(index, 1)
            }
        }
        /**
         * 1: 直接赋值。 新值数量大于等于旧值数量
         * 2：比较后再赋值。
         * @constructor
         * @param _data
         * @param isRefresh
         */
        const SET_USER_WORK_LIST = (_data: IndexResponseItemData[] | IndexResponseItemData, isRefresh = false) => {
            const data: IndexResponseItemData[] = _data && Array.isArray(_data) ? _data : [_data]
            if (isRefresh) {
                userWorkList.value = data
                return data
            }
            if (data.length >= userWorkList.value.length) {
                userWorkList.value = data
                return data
            }
            userWorkList.value = userWorkList.value.map((item: IndexResponseItemData) => {
                const active = data.find((i: IndexResponseItemData) => i.id === item.id)
                if (active) {
                    return {
                        ...item,
                        ...active,
                    }
                }
                return item
            })
        }
        // 调用接口，更新 userWorkList 的数据。常用于用户的身份发生了变化时触发，例如：登录，购买vip等级
        const REQ_SET_USER_WORK_LIST = async (params?: { shareVideoId?: string }) => {
            const isShare = !!params?.shareVideoId
            if (!isShare && !userWorkList.value.length) {
                return []
            }
            if (isShare) {
                SET_USER_WORK_LIST([], true)
            }
            const videoIds = isShare ? [Number(params.shareVideoId)] : userWorkList.value.map((item) => item.id)
            // if (!videoIds.length) {
            //     setUserWorkList([])
            //     return []
            // }
            const res = await reqSingleList({
                userId: MY_USER_INFO.id,
                videoId: <number[]>videoIds,
            })
            if (res.code === 0 && res.data && Array.isArray(res.data)) {
                SET_USER_WORK_LIST(res.data)
            }
            return userWorkList.value
        }

        // 登录
        const getUserInfo = async () => {
            const res = await reqUserInfo()
            Object.assign(MY_USER_INFO, res.data)
            return res.data
        }

        const touristLogin = async () => {
            const res = await reqTouristLogin()
            MY_USER_INFO.id = res.data.id
            REQ_SET_USER_WORK_LIST().then(() => {})
            return res.data
        }

        const LOG_OUT = () => {
            token.value = ''
            IM_TOKEN.value = ''
            // MY_USER_INFO.id === 0 时，登录一些不需要权限的页面，他会调用 touristLogin
            MY_USER_INFO.id = 0
            userWorkList.value = []
        }

        return {
            token,
            SET_TOKEN,
            IM_TOKEN,
            SET_IM_TOKEN,
            GET_IM_TOKEN,
            STORE_IS_LOGIN, // 是否登录
            STORE_IS_REGISTERED, // 是否注册用户
            STORE_IS_TOURIST,
            GET_TOKEN: () => token.value,
            CLEAR_TOKEN,
            MY_USER_INFO,
            tag,
            setTag,
            getUserInfo,
            touristLogin,
            LOG_OUT,
            searchKey,
            searchHistoryArr,
            WORK_ACTIVE_INDEX,
            SET_WORK_ACTIVE_INDEX,
            GET_WORK_ACTIVE_INDEX,
            userWorkList,
            setUserWorkList,
            SET_USER_WORK_LIST,
            REQ_SET_USER_WORK_LIST,
            REMOVE_USER_WORK_LIST,
        }
    },
    {
        persist: {
            paths: ['MY_USER_INFO', 'token', 'IM_TOKEN', 'searchHistoryArr', 'userWorkList'],
            storage: localStorage,
        },
    },
)
