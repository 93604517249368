import { defineStore } from 'pinia'
import { computed, ref, toRefs } from 'vue'
import { reqUserConfig, reqUserVipLevelList } from '@/api/user'
import { IUserVipLevelListItem } from '@/api/home/type'
import { TUserConfigRes } from '@/api/user/type'
import { useUserStore } from '@/store'

/**
 * vip等级列表，多个场景下需要使用。
 * 1. vip页面，调用 GET_VIP_LEVEL_LIST
 * 2.购买视频的引导页面（'@/components/video/VideoPurchaseGuide.vue'）调用 GET_VIP_LEVEL_LIST
 *结合使用场景，直接写到数据仓库里，在login的时候，获取一次，缓存起来。同时，在vip页面中，需要调用一次GET_VIP_LEVEL_LIST。
 */

const loading = ref(false)
export const useVipLevelList = defineStore(
    'vip',
    () => {
        const VIP_LEVEL_LIST = ref<IUserVipLevelListItem[]>([])
        const FIRST_VIP_LEVEL_GOLD = computed(() => {
            return VIP_LEVEL_LIST.value[0]?.score || 0
        })

        /**
         * 获取会员等级列表. 这个值，不会频繁改变，所以，这里用了缓存. 只有在登录的时候，才需要强制刷新。
         * @param isRefresh 强制刷新。登录的时候，传个true进来。
         * @constructor
         */
        const GET_VIP_LEVEL_LIST = (isRefresh = false) => {
            return new Promise((resolve, reject) => {
                if (!isRefresh && VIP_LEVEL_LIST.value.length > 0) {
                    return resolve(VIP_LEVEL_LIST.value)
                }
                if (loading.value) {
                    return VIP_LEVEL_LIST.value
                }
                loading.value = true
                reqUserVipLevelList()
                    .then((res) => {
                        if (res?.code === 0) {
                            if (res.data.length > 0) {
                                VIP_LEVEL_LIST.value = res.data
                                resolve(res.data)
                            }
                            reject(res)
                        }
                        loading.value = false
                        reject(res)
                    })
                    .catch((err) => {
                        loading.value = false
                        reject(err)
                    })
            })
        }
        return {
            VIP_LEVEL_LIST,
            FIRST_VIP_LEVEL_GOLD,
            GET_VIP_LEVEL_LIST,
        }
    },
    {
        persist: {
            storage: localStorage,
        },
    },
)

/**
 * 获取配置信息。其实和有没有登录没有关系。
 * 这里取名叫 userConfig, 还包括了游客。
 */

const loadingConfig = ref(false)
const userId = ref(-1)
const REQUIRED_KEYS = ['awsKey', 'awsSecret', 'awsRegion', 'awsPoint', 'videoDuration', 'maxLookCount', 'event', 'maxUploadSize', 'logsUrl', 'minLoadingTime', 'imWsApi']

export const useUserConfig = defineStore(
    'userConfig',
    () => {
        const { token } = toRefs(useUserStore())
        const USER_CONFIG = ref<TUserConfigRes>({} as TUserConfigRes)
        const isUserConfigValid = computed(() => {
            return REQUIRED_KEYS.every((key) => key in (USER_CONFIG.value || {}))
        })

        const USER_CONFIG_LOGS_URL = computed(() => {
            return USER_CONFIG.value?.logsUrl || ''
        })
        const USER_CONFIG_EVENT = computed(() => {
            return USER_CONFIG.value?.event || []
        })
        // give 模块，尚未启用
        const enabledGiveModel = true
        const USER_CONFIG_EVENT_HAS_GIVE = computed(() => {
            if (!enabledGiveModel) {
                return false
            }
            return !!USER_CONFIG_EVENT.value.filter((item) => item.eventType === 'give').length
        })
        const topMsgVisible = ref(true)
        const IS_SHOW_TOP_MSG = computed(() => {
            if (!topMsgVisible.value) {
                return false
            }
            if (!token.value && USER_CONFIG.value.maxLookCount) {
                return true
            }
            return !!token.value && USER_CONFIG_EVENT_HAS_GIVE.value
        })
        const CLOSE_TOP_MSG = () => {
            console.log('CLOSE_TOP_MSG', USER_CONFIG.value)
            topMsgVisible.value = false
        }
        const IS_REQ_CONFIG = ref(false)

        /**
         * 获取用户配置信息。
         * 不需要缓存。因为登录之后，他的信息会变。
         * @param data
         * @constructor
         */
        const GET_USER_CONFIG = (data: { userId: number }) => {
            return new Promise((resolve, reject) => {
                if (isUserConfigValid.value && userId.value === data.userId) {
                    return resolve(USER_CONFIG.value)
                }
                if (loadingConfig.value) {
                    return USER_CONFIG.value
                }
                loadingConfig.value = true
                IS_REQ_CONFIG.value = true
                userId.value = data.userId
                reqUserConfig(data)
                    .then((res) => {
                        if (res?.code === 0) {
                            USER_CONFIG.value = res.data
                            resolve(res.data)
                        }
                        loadingConfig.value = false
                        reject(res)
                    })
                    .catch((err) => {
                        loadingConfig.value = false
                        reject(err)
                    })
            })
        }

        const USER_CONFIG_WS_URL = computed(() => {
            return USER_CONFIG.value?.imWsApi || ''
        })

        return {
            USER_CONFIG,
            IS_SHOW_TOP_MSG,
            CLOSE_TOP_MSG,
            USER_CONFIG_EVENT_HAS_GIVE,
            GET_USER_CONFIG,
            USER_CONFIG_LOGS_URL,
            USER_CONFIG_WS_URL,
            isUserConfigValid,
        }
    },
    {
        persist: {
            paths: ['USER_CONFIG', 'USER_CONFIG_LOGS_URL'],
            storage: localStorage,
        },
    },
)
