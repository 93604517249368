// roomStore.ts
/**
 * 主要是获取聊天的房间列表。
 * @todo 由于房间列表与未读消息计数在设计中是耦合在一起的，导致在分页加载过程中，若当前页加载的是已读消息，而未读消息存在于下一页，可能会导致 tabBar 未读消息显示异常。
 */
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import i18n from '@/i18n/index.ts'
import { fetchRoomList, reqDeleteRoom, toggleRoomTopStatus } from '@/api/message2'
import { ChatRoomItem, ChatRoomTopTypeKey, RecordsDirectResItem } from '@/api/message2/type'
import { useTalk } from '@/im/composables/useTalk.ts'
import { IM_RECEIVE_MESSAGE_TYPE } from '@/config/constant.ts'
const isTopRoom = 1
const isPlainRoom = 2
const updateAtSort = (a: ChatRoomItem, b: ChatRoomItem) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
//@todo 不太确定，要不要让 topAt 根据置顶时间来排序。例如：接收到新的消息时，置顶列表，是按照置顶时间来排序，还是按照最后一条消息的时间来排序。
// 如果置顶的房间是按照置顶时间来排序，这意味着，新的消息来了，他会保持在原来 的位置 。
// const topAtSort = (a: ChatRoomItem, b: ChatRoomItem) => new Date(b.topAt).getTime() - new Date(a.topAt).getTime()
export const useRoomStore = defineStore('roomStore', () => {
    const { parseExtraInfo } = useTalk()
    // 房间列表状态
    const chatList = ref<ChatRoomItem[]>([]) // 非置顶房间
    const topList = ref<ChatRoomItem[]>([]) // 置顶房间

    // 合并置顶和非置顶房间
    const roomList = computed(() => {
        return [...topList.value, ...chatList.value.sort(updateAtSort)]
    })

    const more = ref(true) // 是否还有更多房间
    const isLoading = ref(false) // 是否正在加载中

    /**
     * 获取房间列表，这里的size只所以设置大一些，是因为我希望能够获取到
     */
    const plainRoomState = ref({
        page: 1,
        size: 100,
    })

    const topRoomState = ref({
        page: 1,
        size: 999, // 如果置顶房间较多，可以用更大的size
        isLoading: false,
    })

    /**
     * 获取非置顶房间列表
     *
     * 更改成了全部房间列表，请求时不再区分置顶状态或者非置顶，而是在列表 中自行处理。
     * 同时，不再分页。但是，这段代码，仍然保留分页功能，防止又改回去。
     * 2025-01-09，
     */
    const getMessageList = async () => {
        if (isLoading.value) return // 避免重复加载

        isLoading.value = true

        try {
            const res = await fetchRoomList({
                page: plainRoomState.value.page,
                size: plainRoomState.value.size,
            })
            if (plainRoomState.value.page === 1) {
                chatList.value = []
                topList.value = []
            }

            if (res.code === 0) {
                const newList = res.data.item || []
                const newLen = newList.length

                // 后台已经按照时间排好序了，这里就不再多做排序的操作。
                const topRoomList = newList.filter((item) => item.isTop === isTopRoom)
                const plainRoomList = newList.filter((item) => item.isTop === isPlainRoom)
                chatList.value = [...chatList.value, ...plainRoomList]
                topList.value = [...topList.value, ...topRoomList]

                // 判断是否还有更多数据
                more.value = newLen === plainRoomState.value.size
                if (newLen > 0) {
                    plainRoomState.value.page++ // 增加分页
                }
            }
        } catch (err) {
            console.error('Failed to fetch room list:', err)
        } finally {
            isLoading.value = false
        }
    }
    // 置顶或取消置顶房间
    const handleDoTop = async (userId: number, isTop: ChatRoomTopTypeKey, index: number) => {
        const newTopStatus = isTop === 1 ? 2 : 1
        const res = await toggleRoomTopStatus({ toFromId: userId, action: newTopStatus })
        if (res.code === 0) {
            const room = isTop === 1 ? topList.value.splice(index, 1)[0] : chatList.value.splice(index - topList.value.length, 1)[0]
            room.isTop = newTopStatus
            if (newTopStatus === 1) {
                // 置顶可以直接排在最上方
                topList.value.unshift(room)
            } else {
                // 取消置顶需要按照时间排序到原来位置。
                chatList.value = [room, ...chatList.value].sort(updateAtSort)
                // chatList.value.unshift(room)
            }
        }
    }

    // 删除房间
    const handleChatDelete = async (userId: number, isTop: ChatRoomTopTypeKey) => {
        const res = await reqDeleteRoom({ toFromId: userId })
        if (res.code === 0) {
            if (isTop === 1) {
                topList.value = topList.value.filter((item) => item.toFromId !== userId)
            } else {
                chatList.value = chatList.value.filter((item) => item.toFromId !== userId)
            }
        }
    }

    const initParam = () => {
        chatList.value = []
        topList.value = []
        plainRoomState.value.page = 1
        topRoomState.value.page = 1
        // 还有没有更多。
        more.value = true
        isLoading.value = false
    }
    /**
     * 更新 topList chatList
     *  2.1 更新对应房间的 msgText 和 updatedAt ("2025-01-09 10:57:10")
     *  2.2 更新对应房间的 unreadCount
     *  2.3 更新对应房间的排序
     * @param msgRecord
     * @param params
     */
    const updateRoomInfo = (msgRecord: RecordsDirectResItem, params: { toFromId: number }) => {
        const targetToFormId = params.toFromId
        // 查找活动房间
        const activeRoom = roomList.value.find((item) => item.toFromId === targetToFormId) as ChatRoomItem
        //@todo 如果说没有找到对应的房间，是直接刷新房间，还是造一个数据，添加一个新的房间？
        if (!activeRoom) {
            //@todo 如果造一个数据，没法知道这个房间是置顶房间，还是非置顶。所以只能刷新
            //@todo 如果需要刷新，需要判断当前页面吗？因为 tabBar 上面，也需要这个数据，所以，不能判断页面。
            refresh()
            return
        } // 如果找不到该房间，直接返回
        const activeRoomStatus = activeRoom.isTop as ChatRoomTopTypeKey // 获取房间的状态
        const content = msgRecord.msgType === IM_RECEIVE_MESSAGE_TYPE.IMAGE ? i18n.global.t('message.chatLastMsgImg') : parseExtraInfo(msgRecord.extra).content
        // 统一处理函数：更新 房间 信息
        const updateRoomList = (list: Array<ChatRoomItem>) => {
            const index = list.findIndex((item) => item.toFromId === targetToFormId)
            if (index !== -1) {
                // 更新 房间 信息
                list[index].msgText = content
                list[index].updatedAt = msgRecord.sendTime
                list[index].nowTime = msgRecord.nowTime
                list[index].unreadNum++

                // 置顶操作，将房间移动到最前面
                list.unshift(list.splice(index, 1)[0])
            }
        }

        // 置顶状态与对应房间列表的映射
        const roomListMapping = {
            [isTopRoom]: topList.value,
            [isPlainRoom]: chatList.value,
        }

        // 获取对应的房间列表
        const targetRoomList = roomListMapping[activeRoomStatus]

        if (targetRoomList) {
            updateRoomList(targetRoomList)
        }
    }

    const refresh = () => {
        initParam()
        Promise.allSettled([getMessageList()]).finally(() => {})
    }

    return {
        chatList,
        topList,
        roomList,
        more,
        isLoading,
        plainRoomState,
        topRoomState,
        getMessageList,
        updateRoomInfo,
        refresh,
        handleDoTop,
        handleChatDelete,
    }
})
